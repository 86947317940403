let origin = "https://future.zhuneng.cn";
//众创空间列表
const createWorkUrl = `/pubApi/external/makerSpace/getApplyList`;

//众创空间申请
const applyCreateWorkList = `/pubApi/external/makerSpace/doApply`;
//详情
const createWorkDetail = `/spaceApi/space/detail`;
//众创空间列表
const workAreaListUrl = `/spaceApi/space/list`;
//状态变更
const updateStatusUrl = `/spaceApi/space/update`;
export {
  createWorkUrl,
  applyCreateWorkList,
  createWorkDetail,
  workAreaListUrl,
  updateStatusUrl,
};
